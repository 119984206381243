.menuWrapper {
  text-align: center;
  background-color: rgba(212, 182, 240, 0.1);
  width: 15%;
  border-left: 1px solid hsl(300, 99%, 60%);
  padding-inline: 1rem;
}
.menuTitle {
  color: hsl(300, 99%, 60%);
  font-size: 3rem;
  border-bottom: 1px solid hsl(300, 99%, 60%);
  padding-block: 2rem;
}

.cdMenu,
.recordsMenu,
.tapesMenu,
.visualMedia {
  margin: 1rem auto;
  color: hsl(300, 99%, 60%);
}
