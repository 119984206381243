.recordsBtnsWrapper {
  border-bottom: 1px solid hsl(300, 99%, 60%);
  padding-block-end: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  color: hsl(300, 99%, 60%);
}

.recBtnInactive {
  width: 75%;
  margin-inline: auto;
  padding-block: 1rem;
  background-color: rgb(50, 22, 77);
  color: hsl(300, 99%, 60%);
  font-weight: bold;
  letter-spacing: 0.05em;
}

.recBtnActive {
  background-color: rgb(212, 182, 240);
  color: black;
}
