.cdBtnsWrapper {
  border-bottom: 1px solid hsl(300, 99%, 60%);
  padding-block-end: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  color: hsl(300, 99%, 60%);
}

.cdBtnInactive {
  width: 75%;
  margin-inline: auto;
  padding-block: 1.25rem;
  background-color: rgb(50, 22, 77);
  color: hsl(300, 99%, 60%);
  font-weight: bold;
  letter-spacing: 0.05em;
}

.cdBtnActive {
  background-color: rgb(212, 182, 240);
  color: black;
}

.cdBtnsWrapper button {
  display: flex;
  justify-content: center;
  align-items: center;
}
