.compsCompWrapper {
  padding: 0em 1em 1em 1em;
  border-color: hsl(300, 99%, 60%);
  color: hsl(300, 99%, 60%);
  border-radius: 20px;
}

.compTracksWrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-inline: auto;
  justify-items: center;
  white-space: nowrap;
  overflow-x: hidden;
  /* max-width: 100%; */
}

.compTrack {
  font-size: 1.25em;
  max-width: 90%;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
