.sidebar {
  background-color: rgba(212, 182, 240, 0.1);
  width: 20%;
  min-height: 100dvh;
  padding-inline: 1rem;
  text-align: center;
  border-right: 1px solid hsl(300, 99%, 60%);
}

.sidebar h1 {
  font-size: clamp(2.5rem, 3vw, 5rem);
  border-bottom: 1px solid hsl(300, 99%, 60%);
  color: hsl(300, 99%, 60%);
  padding-block: 0.25em;
  line-height: 1.1;
}

.modeSelect {
  margin-block: 1.5rem;
  padding: 0.5rem 0 1rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  background-color: rgba(51, 204, 51, 0.3);
  color: hsl(300, 99%, 60%);
}

.entryActive {
  background-color: rgba(255, 0, 0, 0.3);
}
.entryActive h2 {
  color: hsl(300, 99%, 60%);
}
.modeSelectButtonWrapper {
  display: flex;
  justify-content: space-around;
}
.modeSelectButtonWrapper button {
  padding: 1rem 1.5rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 0.05em;
}
.viewing {
  background-color: rgba(0, 255, 0, 0.4);
  color: rgb(0, 255, 0);
  width: 40%;
}
.entry {
  background-color: rgb(241, 78, 78, 0.4);
  color: rgb(255, 0, 0);
  width: 40%;
}

.formatSelectionWrapper {
  border-top: 1px solid hsl(300, 99%, 60%);
  border-bottom: 1px solid hsl(300, 99%, 60%);
  padding-block: 2em 2.5em;
  display: flex;
  flex-direction: column;
  gap: 0.75em;
  color: hsl(300, 99%, 60%);
}

.formatSelectionWrapper h2 {
  margin-bottom: 0.25em;
}

.formatSelectBtn {
  width: 75%;
  margin-inline: auto;
  padding-block: 1rem;
  background-color: rgb(50, 22, 77);
  color: hsl(300, 99%, 60%);
  font-size: 1.25em;
}
.fmtBtnActive {
  background-color: rgb(212, 182, 240);
  color: black;
  font-weight: bold;
  letter-spacing: 1px;
}

.totalsWrapper h3 {
  text-align: center;
  margin-block: 0.5em;
  color: hsl(300, 99%, 60%);
}

.totals {
  color: hsl(300, 99%, 60%);
  font-size: 1.25em;
  display: grid;
  gap: 0.3em;
}

/* .totals p {
    margin-top:
} */

@media screen and (width > 1700px) {
  .formatSelectBtn {
    padding-block: 1.5rem;
  }
  .totalsWrapper h3 {
    margin-top: 50px;
  }
}
