* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

button {
  border: none;
}

button:active {
  /* transform: translate(1px, 1px); */
  outline: 2px solid rgb(212, 182, 240);
  font-weight: bold;
}

.App {
  display: flex;
  background-color: black;
}
